import { io } from "socket.io-client";

export const socketInit = () => {
  const options = {
    "force new connections": true,
    reconneectionAttempt: "Infinity",
    timeout: 10000, //10k ms
    transports: ["websocket"],
  };

  return io("https://collabrewserver.onrender.com", options);
};

// https://collabrew.herokuapp.com/
// https://collabrewserver.onrender.com
// http://localhost:5000
